import { useEffect, useState } from 'react';
import { DayRangePicker, TDateRange } from 'src/components/ui-components';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useGetCurrentLanguage } from 'src/apis/userSettingsAPI';
import { getLanguageFromSiteLocale, localeDateFormat } from 'src/utils/date';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import { ViewOptionsChangeParameters } from 'src/components/layout/FilterLayout/types';
import { TFunction } from 'i18next';
import { DayPickerBase } from 'react-day-picker';
import { TFrom, TPeriodType } from 'src/components/ui-components/DatePicker/DayRangePicker/types';

type PreSelectOptionsKey = 'defaultPreSelectOption' | 'pastPreSelectOptions';

interface ResourceViewOptionsProps {
  onChange?: (item: ViewOptionsChangeParameters[]) => void;
  initialDateStart?: Date;
  initialDateEnd?: Date;
  preSelectOptions?: PreSelectOptionsKey;
  setPreDefinedPeriod?: (value: string) => void;
}

export const getPreSelectOptionsList = (t: TFunction) => ({
  defaultPreSelectOption: [
    {
      name: 'OneWeek',
      label: t('PreselectButtonOneWeek'),
      periodType: 'week' as TPeriodType,
      periodAmount: 1,
    },
    {
      name: 'OneMonth',
      label: t('PreselectButtonOneMonth'),
      periodType: 'month' as TPeriodType,
      periodAmount: 1,
    },
    {
      name: 'ThreeMonths',
      label: t('PreselectButtonThreeMonths'),
      periodType: 'month' as TPeriodType,
      periodAmount: 3,
    },
    {
      name: 'OneYear',
      label: t('PreselectButtonOneYear'),
      periodType: 'year' as TPeriodType,
      periodAmount: 1,
    },
  ],
  pastPreSelectOptions: [
    {
      name: 'ThisWeek',
      label: t('PreselectButtonThisWeek'),
      periodType: 'week' as TPeriodType,
      periodAmount: 1,
      from: 'this' as TFrom,
      weekStartsOn: 1 as DayPickerBase['weekStartsOn'],
    },
    {
      name: 'LastOneWeek',
      label: t('PreselectButtonPreviousWeek'),
      periodType: 'week' as TPeriodType,
      periodAmount: 1,
      from: 'last' as TFrom,
    },
    {
      name: 'ThisMonth',
      label: t('PreselectButtonThisMonth'),
      periodType: 'month' as TPeriodType,
      periodAmount: 1,
      from: 'this' as TFrom,
    },
    {
      name: 'LastOneMonth',
      label: t('PreselectButtonPreviousMonth'),
      periodType: 'month' as TPeriodType,
      periodAmount: 1,
      from: 'last' as TFrom,
    },
  ],
});

export const PeriodPicker = ({
  onChange,
  initialDateStart,
  initialDateEnd,
  preSelectOptions = 'defaultPreSelectOption',
  setPreDefinedPeriod,
}: ResourceViewOptionsProps) => {
  const { t } = useTranslation('dayPicker');
  const preSelectOptionsList = getPreSelectOptionsList(t);

  // Date picker options
  const { currentLanguage } = useGetCurrentLanguage();
  const siteLocale = useGetLocale();
  const [dateRange, setDateRange] = useState<TDateRange | undefined>({
    from: initialDateStart,
    to: initialDateEnd,
  });

  useEffect(() => {
    setDateRange({
      from: initialDateStart,
      to: initialDateEnd,
    });
  }, [initialDateStart, initialDateEnd]);

  const handleDateSelect = () => {
    if (dateRange?.from && dateRange?.to) {
      onChange?.([
        {
          name: 'period-starts-at',
          value: format(dateRange.from, 'yyyy-MM-dd'),
        },
        { name: 'period-ends-at', value: format(dateRange.to, 'yyyy-MM-dd') },
      ]);
    }
  };

  const siteLanguage = getLanguageFromSiteLocale(siteLocale);

  return (
    <DayRangePicker
      data-automation-id="ResourcePlannerPeriodPicker"
      mode="range"
      dateRange={dateRange}
      setDateRange={setDateRange}
      fromDate={preSelectOptions === 'pastPreSelectOptions' ? undefined : new Date()}
      weekStartsOn={1}
      showWeekNumber
      onApplyHandler={handleDateSelect}
      inputFromLabel={t('DateInputFromLabel')}
      inputToLabel={t('DateInputToLabel')}
      buttonApplyText={t('ApplyButtonText')}
      buttonCancelText={t('CancelButtonText')}
      dateFormatInvalidMessage={t('NotValidDateFormatMessage')}
      dateInvalidMessage={t('NotValidDate')}
      startDateIsOutOfRangeMessage={t('StartDateIsBeforeAllowedDateMessage')}
      endDateIsOutOfRangeMessage={t('EndDateIsBeforeAllowedDateMessage')}
      dateFormat={localeDateFormat(siteLanguage || 'da')}
      preselectOptions={preSelectOptionsList[preSelectOptions]}
      setPreDefinedPeriod={setPreDefinedPeriod}
      labels={{
        labelPrevious: (labelMonth) =>
          `${t('PreviousMonthButtonText')}${
            labelMonth
              ? `: ${labelMonth.toLocaleString(currentLanguage, {
                  month: 'long',
                })}`
              : ''
          }`,
        labelNext: (labelMonth) =>
          `${t('NextMonthButtonText')}${
            labelMonth
              ? `: ${labelMonth.toLocaleString(currentLanguage, {
                  month: 'long',
                })}`
              : ''
          }`,
        labelWeekNumber: (weekNumber) => `${t('PickEntireWeekButtonText')} ${weekNumber}`,
      }}
    />
  );
};
