import { Link, Shimmer } from 'src/components/ui-components';
import { useTranslation } from 'react-i18next';
import { useGetNotificationCenter, useGetIsApprovalEnabled } from 'src/apis/notificationCenterAPI';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import { WidgetHeader } from 'src/screens/Home/components/WidgetHeader';
import { formatUrl } from 'src/utils/url';
import { useLocalStorage } from 'src/hooks/useLocalStorage';
import { Alert, Button, Stack, Typography } from 'src/components/mui-components';
import { GppMaybeOutlined } from '@mui/icons-material';
import { AccordionItem, InfoModal, ListItem } from './components';

export const notificationCenterExpandAllStateLocalStorageKey = 'notification-center-expand-state';

export const NotificationCenter = () => {
  const {
    timeSheetsToApprove,
    absenceToApprove,
    itemsToTakeActionOn,
    notificationCenterTimesheetItems,
    numberOfExpensesPendingApproval = 0,
    numberOfMileagePendingApproval = 0,
    approveExpensesUrl,
    isApprovalOfExpensesEnabled,
    isApprovalOfTimeSheetEnabled,
    isManagerForActiveEmployees,
    isApprovalOfTimesheetByProjectManagerOnlyEnabled,
    closeTimeSheetReportUrl,
    approvalUrl,
    timeSheetsToBeClosedLength,
    isError,
    isLoading,
  } = useGetNotificationCenter();
  const { isApprovalEnabled } = useGetIsApprovalEnabled();
  const { t } = useTranslation('notificationCenter');
  const accordionItemsTimeSheets = isApprovalOfTimeSheetEnabled ? 3 : 0;
  const accordionItemsExpenses = isApprovalOfExpensesEnabled ? 2 : 0;
  const accordionItems = accordionItemsTimeSheets + accordionItemsExpenses;
  const defaultIndices = Array.from({ length: accordionItems }, (value, index) => index);
  const [indices, setIndices] = useLocalStorage(
    notificationCenterExpandAllStateLocalStorageKey,
    defaultIndices,
  );
  const showNotificationsCenter =
    isApprovalEnabled && (isApprovalOfTimeSheetEnabled || isApprovalOfExpensesEnabled);

  const expandCollapseHandler = () => {
    if (indices.length === defaultIndices.length) {
      return setIndices([]);
    }

    return setIndices(defaultIndices);
  };

  const toggleItem = (toggledIndex: number) => {
    if (indices.includes(toggledIndex)) {
      setIndices(indices.filter((currentIndex) => currentIndex !== toggledIndex));
    } else {
      setIndices([...indices, toggledIndex].sort());
    }
  };

  return (
    <ResponseHandler
      isError={isError}
      isLoading={isLoading}
      LoadingComponent={<Shimmer />}
      ErrorComponent={
        <Alert color="error" data-automation-id="NotificationCenterAlertError">
          <Typography>{t('CouldNotLoadNotificationCenterErrorMessage')}</Typography>
        </Alert>
      }
    >
      {showNotificationsCenter ? (
        <Stack gap={2} data-automation-id="NotificationCenter">
          <WidgetHeader
            widgetKey="NotificationCenter"
            heading={t('Heading')}
            headingAs="h2"
            icon={<GppMaybeOutlined data-automation-id="NotificationCenterHeaderIcon" />}
            count={itemsToTakeActionOn}
            data-automation-id="NotificationCenterHeader"
            action={<InfoModal />}
          />

          <div>
            {accordionItems > 0 && (
              <Stack direction="row" justifyContent="end">
                <Button
                  variant="outlined"
                  size="small"
                  onClick={expandCollapseHandler}
                  aria-owns="notification-center-accordion"
                  data-automation-id="NotificationCenterExpandCollapseButton"
                >
                  {indices.length !== defaultIndices.length
                    ? t('ExpandAllButtonText')
                    : t('CollapseAllButtonText')}
                </Button>
              </Stack>
            )}

            <div id="notification-center-accordion">
              {isApprovalOfTimeSheetEnabled && (
                <>
                  {notificationCenterTimesheetItems && isManagerForActiveEmployees && (
                    <AccordionItem
                      buttonAutomationId="TimeSheetsToBeClosedHeading"
                      heading={t('TimeSheetsToCloseHeading')}
                      counter={timeSheetsToBeClosedLength}
                      dataAutomationId="NotificationCenterItemTimeSheetsToBeClosed"
                      handleChange={() => toggleItem(0)}
                      expanded={indices.includes(0)}
                    >
                      {notificationCenterTimesheetItems.length > 0 ? (
                        <>
                          <Stack>
                            {notificationCenterTimesheetItems.map((item, index) =>
                              item.weeklyTimesheetDetails.map((timeSheet, childIndex) => (
                                <ListItem
                                  name={item.employeeFullName}
                                  employeeId={item.employeeUserId}
                                  hours={timeSheet.hours}
                                  normalWorkingTimeHoursInPeriod={timeSheet.normalWorkingTime}
                                  startDate={timeSheet.startDate}
                                  endDate={timeSheet.endDate}
                                  // we can't get and id from backend
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={`timesheet-items-${item.employeeUserId}-${index}-${childIndex}`}
                                />
                              )),
                            )}
                          </Stack>

                          <Link
                            href={formatUrl(closeTimeSheetReportUrl)}
                            data-automation-id="CloseTimeSheetsReportLink"
                          >
                            {t('TimeSheetsToCloseLinkText')}
                          </Link>
                        </>
                      ) : (
                        <Alert
                          color="success"
                          data-automation-id="NotificationCenterAlertTimeSheetsToBeClosed"
                        >
                          <Typography>{t('TimeSheetsToCloseDoneText')}</Typography>
                        </Alert>
                      )}
                    </AccordionItem>
                  )}

                  {timeSheetsToApprove && !isApprovalOfTimesheetByProjectManagerOnlyEnabled && (
                    <AccordionItem
                      buttonAutomationId="TimeSheetsToApproveHeading"
                      heading={t('TimeSheetsToApproveHeading')}
                      counter={timeSheetsToApprove.length}
                      dataAutomationId="NotificationCenterItemTimeSheets"
                      handleChange={() => toggleItem(1)}
                      expanded={indices.includes(1)}
                    >
                      {timeSheetsToApprove.length > 0 ? (
                        <>
                          <Stack>
                            {timeSheetsToApprove.map((item, index) => (
                              <ListItem
                                name={item.employeeInfo}
                                employeeId={item.employeeUserId}
                                hours={item.hours}
                                normalWorkingTimeHoursInPeriod={item.normalWorkingTimeHoursInPeriod}
                                startDate={item.startDate}
                                endDate={item.endDate}
                                url={item.approvalForUserUrl}
                                linkText={t('TimeSheetsToApproveSingleLinkToolTip', {
                                  EMPLOYEE_NAME: item.employeeInfo,
                                })}
                                navigationItemType={item.navigationItemType}
                                // we can't get and id from backend
                                // eslint-disable-next-line react/no-array-index-key
                                key={`timesheet-approve-${item.employeeUserId}-${index}`}
                              />
                            ))}
                          </Stack>

                          <Link
                            href={formatUrl(approvalUrl)}
                            data-automation-id="ApproveTimeSheetsLink"
                          >
                            {t('TimeSheetsToApproveLinkText')}
                          </Link>
                        </>
                      ) : (
                        <Alert
                          color="success"
                          data-automation-id="NotificationCenterAlertTimeSheetsToBeApproved"
                        >
                          <Typography>{t('TimeSheetsToApproveDoneText')}</Typography>
                        </Alert>
                      )}
                    </AccordionItem>
                  )}

                  {absenceToApprove && isManagerForActiveEmployees && (
                    <AccordionItem
                      buttonAutomationId="AbsenceToApproveHeading"
                      heading={t('AbsenceToApproveHeading')}
                      counter={absenceToApprove.length}
                      dataAutomationId="NotificationCenterItemAbsence"
                      handleChange={() => toggleItem(2)}
                      expanded={indices.includes(2)}
                    >
                      {absenceToApprove.length > 0 ? (
                        <>
                          <Stack>
                            {absenceToApprove.map((item, index) => (
                              <ListItem
                                name={item.employeeInfo}
                                employeeId={item.employeeUserId}
                                url={item.approvalForUserUrl}
                                linkText={t('AbsenceToApproveSingleLinkToolTip', {
                                  EMPLOYEE_NAME: item.employeeInfo,
                                })}
                                navigationItemType={item.navigationItemType}
                                startDate={item.startDate}
                                endDate={item.endDate}
                                // we can't get and id from backend
                                // eslint-disable-next-line react/no-array-index-key
                                key={`absence-${item.employeeUserId}-${index}`}
                              />
                            ))}
                          </Stack>

                          <Link
                            href={formatUrl(approvalUrl)}
                            data-automation-id="ApproveAbsenceLink"
                          >
                            {t('AbsenceToApproveLinkText')}
                          </Link>
                        </>
                      ) : (
                        <Alert
                          color="success"
                          data-automation-id="NotificationCenterAlertAbsenceToBeApproved"
                        >
                          <Typography>{t('AbsenceToApproveDoneText')}</Typography>
                        </Alert>
                      )}
                    </AccordionItem>
                  )}
                </>
              )}

              {isApprovalOfExpensesEnabled && (
                <>
                  <AccordionItem
                    buttonAutomationId="ExpensesToApproveHeading"
                    heading={t('ExpensesToApproveHeading')}
                    counter={numberOfExpensesPendingApproval}
                    dataAutomationId="NotificationCenterItemExpenses"
                    handleChange={() => toggleItem(3)}
                    expanded={indices.includes(3)}
                  >
                    {numberOfExpensesPendingApproval > 0 ? (
                      approveExpensesUrl && (
                        <Link
                          href={`${formatUrl(approveExpensesUrl)}?expenses=true`}
                          data-automation-id="ApproveExpensesLink"
                        >
                          {t('ExpensesToApproveLinkText')}
                        </Link>
                      )
                    ) : (
                      <Alert
                        color="success"
                        data-automation-id="NotificationCenterAlertExpensesToBeApproved"
                      >
                        <Typography>{t('ExpensesToApproveDoneText')}</Typography>
                      </Alert>
                    )}
                  </AccordionItem>

                  <AccordionItem
                    buttonAutomationId="MileageToApproveHeading"
                    heading={t('MileageToApproveHeading')}
                    counter={numberOfMileagePendingApproval}
                    dataAutomationId="NotificationCenterItemMileage"
                    handleChange={() => toggleItem(4)}
                    expanded={indices.includes(4)}
                  >
                    {numberOfMileagePendingApproval > 0 ? (
                      approveExpensesUrl && (
                        <Link
                          href={`${formatUrl(approveExpensesUrl)}?mileage=true`}
                          data-automation-id="ApproveMileageLink"
                        >
                          {t('MileageToApproveLinkText')}
                        </Link>
                      )
                    ) : (
                      <Alert
                        color="success"
                        data-automation-id="NotificationCenterAlertMileageToBeApproved"
                      >
                        <Typography>{t('MileageToApproveDoneText')}</Typography>
                      </Alert>
                    )}
                  </AccordionItem>
                </>
              )}
            </div>
          </div>
        </Stack>
      ) : null}
    </ResponseHandler>
  );
};
