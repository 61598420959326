import { useQuery } from '@tanstack/react-query';
import { TFilterType } from 'src/apis/types/filterListAPI';
import { getFetch } from 'src/utils/fetcher';
import { pascalToKebab } from 'src/utils/string/string';
import { GET_SAVED_VIEW_KEY, GET_SAVED_VIEW_MSW_STATUS_KEY } from '../constants';
import { INewSavedView, ISavedViewResponse, TViewOptions, ViewOptionsValues } from '../types';

const GET_SAVED_VIEW_URL_PATH = '/api/v2/filter-view';

const viewOptionsFilterTypes: TFilterType[] = ['Textbox', 'DateRange'];

const transformViewOptions = (viewOptions: ViewOptionsValues[]) =>
  viewOptions.reduce((acc: { [key: string]: string }, option) => {
    acc[pascalToKebab(option.name)] = option.value;
    return acc;
  }, {});

const getSavedView = (pageIdentifier: string): Promise<ISavedViewResponse> =>
  getFetch({
    path: `${GET_SAVED_VIEW_URL_PATH}/${pageIdentifier}`,
    key: GET_SAVED_VIEW_MSW_STATUS_KEY,
  });

export const useGetSavedView = (pageIdentifier: string) =>
  useQuery([GET_SAVED_VIEW_KEY, pageIdentifier], () => getSavedView(pageIdentifier), {
    enabled: !!pageIdentifier,
    select: (data) => {
      // Split into filterValues and viewOptions
      const filteredSaveView = data?.entities
        ? data.entities.map(({ properties }) => {
            const viewOptions: ViewOptionsValues[] = [];
            const filterValues = properties.filterValues
              ?.map((filterValue) => {
                if (viewOptionsFilterTypes.includes(filterValue.filterType as TFilterType)) {
                  viewOptions.push(filterValue as ViewOptionsValues);
                  return null;
                }
                return {
                  ...filterValue,
                };
              })
              .filter(Boolean);

            return {
              ...properties,
              filterValues,
              viewOptions,
            };
          })
        : [];

      // Convert key to label
      const savedView = filteredSaveView.map((view) => ({
        ...view,
        filterValues: view?.filterValues?.map((filterValue) => ({
          ...filterValue,
          value: Array.isArray(filterValue?.value)
            ? filterValue?.value.map((val) => {
                const { key, ...rest } = val;
                return {
                  ...rest,
                  ...(key !== null && { label: key }),
                };
              })
            : filterValue?.value,
        })),
      }));

      // Convert DateRange
      const updatedViewOptionsList = savedView.map((view) => ({
        ...view,
        viewOptions: transformViewOptions(view.viewOptions) as TViewOptions,
        filterValues: view.filterValues.map((filterValue) => ({
          ...filterValue,
          value: filterValue.value || [],
        })),
      }));

      return updatedViewOptionsList as INewSavedView[];
    },
  });
